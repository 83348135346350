import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const personsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "personsApi",
    baseUrl: "/api/persons",
  }),
  reducerPath: "personsApi",
  tagTypes: ["Persons", "Person"],
  endpoints: (builder) => ({
    getPersons: builder.query<any, { include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Persons" }],
    }),
    getPersonById: builder.query({
      query: (id) => id,
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Person" }],
    }),
    searchPeople: builder.query({
      query: (search) => {
        return `/search?name=${search}`;
      },
    }),
    updatePersonById: builder.mutation<any, { id: string; personData: any }>({
      query: ({ id, personData }) => ({
        url: `/${id}`,
        method: "PUT",
        body: personData,
      }),
    }),
    createRelationship: builder.mutation<any, { name: string; email: string }>({
      query: (relationship) => ({
        url: "/",
        method: "POST",
        body: relationship,
      }),
    }),
    createPersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneNumber: string }
    >({
      query: ({ id, phoneNumber }) => ({
        url: `/${id}/phone`,
        method: "POST",
        body: { phoneNumber },
      }),
    }),
    updatePersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneId: string; phoneNumber: string }
    >({
      query: ({ id, phoneId, phoneNumber }) => ({
        url: `/${id}/phone/${phoneId}`,
        method: "PUT",
        body: { phoneNumber },
      }),
    }),
    deletePersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneId: string }
    >({
      query: ({ id, phoneId }) => ({
        url: `/${id}/phone/${phoneId}`,
        method: "DELETE",
      }),
    }),
    createPersonEmail: builder.mutation<
      any,
      { id: string; emailAddress: string }
    >({
      query: ({ id, emailAddress }) => ({
        url: `/${id}/email`,
        method: "POST",
        body: { emailAddress },
      }),
    }),
    updatePersonEmail: builder.mutation<
      any,
      { id: string; emailAddress: string }
    >({
      query: ({ id, emailAddress }) => ({
        url: `/${id}/email`,
        method: "PUT",
        body: { emailAddress },
      }),
    }),
    createPersonNote: builder.mutation<any, { id: string; text: string }>({
      query: ({ id, text }) => ({
        url: `/${id}/notes`,
        method: "POST",
        body: { text },
      }),
    }),
    getPersonNotes: builder.query({
      query: (id) => {
        return `/${id}/notes`;
      },
    }),
    updateNote: builder.mutation<
      any,
      { id: string; noteId: string; text: string }
    >({
      query: ({ id, noteId, text }) => ({
        url: `/${id}/notes/${noteId}`,
        method: "PUT",
        body: { text },
      }),
    }),
    deleteNote: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/notes/${id}`,
        method: "DELETE",
      }),
    }),
    createPersonRating: builder.mutation<
      any,
      { id: string; value: number; text: string }
    >({
      query: ({ id, value, text }) => ({
        url: `/${id}/ratings`,
        method: "POST",
        body: { value, text },
      }),
    }),
    updatePersonRating: builder.mutation<
      any,
      { id: string; ratingId: string; value: number; text: string }
    >({
      query: ({ id, ratingId, value, text }) => ({
        url: `/${id}/ratings/${ratingId}`,
        method: "PUT",
        body: { value, text },
      }),
    }),
    deleteRating: builder.mutation<any, { ratingId: string }>({
      query: ({ ratingId }) => ({
        url: `/ratings/${ratingId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useLazyGetPersonsQuery,
  useLazyGetPersonByIdQuery,
  useGetPersonByIdQuery,
  useSearchPeopleQuery,
  useUpdatePersonByIdMutation,
  useCreateRelationshipMutation,
  useCreatePersonPhoneNumberMutation,
  useUpdatePersonPhoneNumberMutation,
  useDeletePersonPhoneNumberMutation,
  useCreatePersonEmailMutation,
  useUpdatePersonEmailMutation,
  useCreatePersonNoteMutation,
  useGetPersonNotesQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useCreatePersonRatingMutation,
  useUpdatePersonRatingMutation,
  useDeleteRatingMutation,
} = personsApi;
