import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { supabase } from "@/utilities/supabase";

interface CreateBaseApiProps {
  reducerPath: string;
  baseUrl: string;
}

export const createBaseApiWithAuth = ({
  reducerPath,
  baseUrl,
}: CreateBaseApiProps) => {
  return {
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async (headers) => {
        const {
          data: {
            session: { access_token },
          },
        }: any = await supabase.auth.getSession();
        if (access_token) {
          headers.set("authorization", `Bearer ${access_token}`);
        }
        return headers;
      },
    }),
  };
};
