import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EventTemplatesWizardState = {
  description: string;
  order: number;
};

const initialState: EventTemplatesWizardState = {
  description: "",
  order: 0,
};

const eventTemplatesWizardSlice = createSlice({
  name: "eventTemplatesWizard",
  initialState,
  reducers: {
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setOrder: (state, action: PayloadAction<number>) => {
      state.order = action.payload;
    },
    resetEventTemplatesWizard: () => initialState,
  },
});

export const { setDescription, setOrder, resetEventTemplatesWizard } =
  eventTemplatesWizardSlice.actions;
export default eventTemplatesWizardSlice.reducer;
