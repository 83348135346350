import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const workflowTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "workflowTemplatesApi",
    baseUrl: "/api/workflow-templates",
  }),
  reducerPath: "workflowTemplatesApi",
  tagTypes: ["WorkflowTemplates", "WorkflowTemplate", "WorkflowTemplateStep"],

  endpoints: (builder) => ({
    getWorkflowTemplates: builder.query<any, { include?: string }>({
      query: (args) => {
        // TODO: add support for params (type=ALL, and limit (pagination))
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `${queryParams}`;
      },
      providesTags: [{ type: "WorkflowTemplates" }],
    }),
    getWorkflowTemplatesById: builder.query<
      any,
      { id?: string; include?: string }
    >({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "WorkflowTemplate" }],
    }),

    getWorkflowTemplateStepById: builder.query<
      any,
      { workflowId: string; stepId: string }
    >({
      query: (args) => {
        return `/${args.workflowId}/step-templates/${args.stepId}`;
      },
      providesTags: [{ type: "WorkflowTemplateStep" }],
    }),
    createWorkflowTemplate: builder.mutation<
      any,
      { name: string; type: string; dueDate: string; active: boolean }
    >({
      query: ({ name, type, dueDate, active }) => ({
        url: "/",
        method: "POST",
        body: { name, type, dueDate, active },
      }),
    }),
    updateWorkflowTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    createStepTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/step-templates`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetWorkflowTemplatesQuery,
  useGetWorkflowTemplatesByIdQuery,
  useGetWorkflowTemplateStepByIdQuery,
  useCreateWorkflowTemplateMutation,
  useUpdateWorkflowTemplateMutation,
  useCreateStepTemplateMutation,
} = workflowTemplatesApi;
