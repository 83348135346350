import React from "react";

interface SkeletonProps {
  numSkeletons?: number;
  className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  numSkeletons = 1,
  className = "",
}) => {
  const skeletons = Array.from({ length: numSkeletons }, (_, index) => index);

  return (
    <>
      {skeletons.map((_, index) => (
        <div
          key={index}
          className={`animate-pulse bg-stone-300 ${className} ${
            className.includes("rounded-full") ? "rounded-full" : "rounded"
          } my-2`}
        ></div>
      ))}
    </>
  );
};
