import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const dealsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "dealsApi",
    baseUrl: "/api/deals",
  }),
  reducerPath: "dealsApi",
  tagTypes: ["Deals"],
  endpoints: (builder) => ({
    getDeals: builder.query<any, { include?: string; limit?: number }>({
      query: (args) => {
        const params = new URLSearchParams();

        if (args.include) {
          params.append("include", args.include);
        }

        if (args.limit) {
          params.append("limit", args.limit?.toString());
        }

        return `?${params.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: any) => ({ type: "Deals", id } as const)
              ),
              "Deals",
            ]
          : ["Deals"],
    }),
    getDealById: builder.query<any, { id?: string; include: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      providesTags: ({ id }) => [{ type: "Deals", id }],
    }),
    createDeal: builder.mutation<any, any>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Deals" }],
    }),
    deleteDeal: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Deals" }],
    }),
    getMissingFieldsForDeal: builder.query<any, { id?: string }>({
      query: (args) => `/${args.id}/documents/fields?status=UNRESOLVED`,
    }),
  }),
});

export const {
  useGetDealsQuery,
  useGetDealByIdQuery,
  useCreateDealMutation,
  useDeleteDealMutation,
  useGetMissingFieldsForDealQuery,
} = dealsApi;
