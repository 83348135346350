import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const workflowsUiSlice = createSlice({
  name: "workflowsUi",
  initialState,
  reducers: {},
});

export const {} = workflowsUiSlice.actions;
export default workflowsUiSlice.reducer;
