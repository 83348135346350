import React from "react";

type TagProps = {
  name: string;
  className?: string;
};

export const Tag: React.FC<TagProps> = ({ name, className = "" }) => {
  return (
    <div
      className={`text-xs border border-gray-200 rounded-full py-0.5 px-2 shadow-sm ${className}`}
    >
      {name}
    </div>
  );
};
