import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const documentTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "documentTemplatesApi",
    baseUrl: "/api/document-templates",
  }),
  reducerPath: "documentTemplatesApi",
  endpoints: (builder) => ({
    getDocumentTemplates: builder.query<any, { include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return queryParams;
      },
      keepUnusedDataFor: 0,
    }),
    getDocumentTemplatesWithFieldsById: builder.query<
      any,
      { id?: string; include?: string }
    >({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}/fields${queryParams}`;
      },
      keepUnusedDataFor: 0,
    }),
    updateDocumentTemplateById: builder.mutation<
      any,
      { id: string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    patchDocumentTemplateById: builder.mutation<any, { id: string; data: any }>(
      {
        query: ({ id, data }) => ({
          url: `/${id}`,
          method: "PATCH",
          body: data,
        }),
      }
    ),
    createDocumentTemplate: builder.mutation<
      any,
      { type: string; name: string; folderName?: string; order?: number }
    >({
      query: ({ type, name, folderName, order }) => ({
        url: `/`,
        method: "POST",
        body: {
          name,
          type,
          folderName,
          order: order || 0, // TODO: what order do we put for templates not on a workflow?
        },
      }),
    }),
    deleteDocumentTemplate: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetDocumentTemplatesQuery,
  useGetDocumentTemplatesWithFieldsByIdQuery,
  useUpdateDocumentTemplateByIdMutation,
  usePatchDocumentTemplateByIdMutation,
  useCreateDocumentTemplateMutation,
  useDeleteDocumentTemplateMutation,
} = documentTemplatesApi;
