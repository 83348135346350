import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { isNullOrUndefined } from "@/utilities";
import { setAuthUiState } from "@/slices/auth/authUi";
import { useAppDispatch } from "@/hooks/redux";
import { useLazyGetUserByIdQuery } from "@/slices/users/usersApi";

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

export const Auth = ({ children }: any) => {
  const [sessionState, setSessionState]: any = useState(null);
  const dispatch = useAppDispatch();
  /* API */
  const [triggerGetUserById] = useLazyGetUserByIdQuery();

  const navigate = useNavigate();

  const handleSessionChange = async (session: any) => {
    setSessionState(session);
    if (!session || isNullOrUndefined(session)) {
      navigate("/login");
    } else {
      const { data: userRes } = await triggerGetUserById(
        session?.user?.id,
        false
      );
      dispatch(setAuthUiState(userRes));
    }
  };

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => handleSessionChange(session));
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      handleSessionChange(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return sessionState ? children : null;
};
