import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const signaturesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "signaturesApi",
    baseUrl: "/api/signatures",
  }),
  reducerPath: "signaturesApi",
  tagTypes: ["Signatures"],
  endpoints: (builder) => ({
    getSignatures: builder.query<any, { include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `${queryParams}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: any) => ({ type: "Signatures", id } as const)
              ),
              "Signatures",
            ]
          : ["Signatures"],
    }),
    getSignatureById: builder.query<any, { id?: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      providesTags: ({ id }) => [{ type: "Signatures", id }],
    }),
    createSignature: builder.mutation<any, any>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Signatures" }],
    }),
    updateSignature: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [{ type: "Signatures" }],
    }),
    deleteSignature: builder.mutation<any, { id: string }>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Signatures" }],
    }),
  }),
});

export const {
  useGetSignaturesQuery,
  useGetSignatureByIdQuery,
  useCreateSignatureMutation,
  useUpdateSignatureMutation,
  useDeleteSignatureMutation,
} = signaturesApi;
